import React from "react";
import { graphql } from "gatsby";
import NotFound from "@views/NotFound";
import { NotFoundProps } from "src/types";

export const pageQuery = graphql`
  query NotFoundQuery {
    heroDesktop: file(relativePath: { eq: "bg/desktop/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroMobile: file(relativePath: { eq: "bg/mobile/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 625, maxHeight: 1104, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function NotFoundRoot(props: NotFoundProps) {
  return <NotFound {...props} />;
}
