import React from "react";
import BgImage from "gatsby-background-image";
import Layout from "@components/Layout";
import { NotFoundProps } from "src/types";

import styles from "./notfound.module.scss";

export default function NotFound(props: NotFoundProps) {
  const { heroDesktop, heroMobile } = props.data;

  // Art directing background image
  const bg = [
    heroMobile.childImageSharp.fluid,
    {
      ...heroDesktop.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  return (
    <Layout>
      <BgImage Tag="section" fluid={bg} className={styles.notFound}>
        <div className={styles.content}>
          <h1>404 : Halaman yang kamu cari tidak ditemukan :(</h1>
          <span onClick={() => window.history.back()}>Kembali</span>
        </div>
      </BgImage>
    </Layout>
  );
}
